













































import { Vue, Component, Emit, Model, Prop } from "vue-property-decorator";
import { api_customer } from "@/api";
import { More } from "@/mixin/more";
import { OtherModule } from "@/store/other";
@Component
export default class extends More(api_customer.get_notice) {
  go_info(id: number): void {
    this.$router.push(`/customer/notice?id=${id}`);
  }
  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
