













































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { api_customer, notice_info } from "@/api";
import Inner from "@/components/inner/index.vue";
import { OtherModule } from "@/store/other";
@Component({
  components: {
    Inner,
  },
})
export default class extends Vue {
  @Prop({ required: true, type: Number })
  id!: number;

  @Watch("id", { immediate: true })
  watch_id() {
    this.get_info();
  }

  async get_info() {
    this.info = {
      next: null,
      prev: null,
      id: 0,
      title: "",
      content: "",
      create_time: "",
    };
    this._loading = true;
    this.info = await api_customer.get_notice_info(this.id).finally(() => {
      this._loading = false;
    });
  }

  info: notice_info = {
    next: null,
    prev: null,
    id: 0,
    title: "",
    content: "",
    create_time: "",
  };

  change_id(id: number): void {
    this.$router.push(`/customer/notice?id=${id}`);
  }

  ret_list(): void {
    this.$router.push("/customer/notice");
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
